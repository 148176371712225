/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const WhoAmI = () => (
  <section>
    <p>Front-End developer, passionate about creating new experiences and facing challenges.</p>
  </section>
);

export default WhoAmI;
