import React from 'react';
import styled from 'styled-components';
// import { Link } from 'gatsby';

import WhoAmI from '../components/main/WhoAmI';
import MainHeader from '../components/main/Header';
import Card from '../components/common/Card';
import Title from '../components/common/Title';

import Layout from '../components/Layout';

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;  

  &> div:not(:last-child) {
    margin-bottom: 1.45rem;
  }

  @media (min-width: 768px) {
    align-items: initial;
    flex-direction: row;

    &> div:not(:last-child) {
      margin-right: 1.45rem;
    }
  }
`;

const IndexPage = () => (
  <Layout>
    <Card>
      <HeaderWrapper>
        <MainHeader />
        <div>
          <Title>Borja Guzmán</Title>
          <WhoAmI />
        </div>
      </HeaderWrapper>
    </Card>
  </Layout>
);

export default IndexPage;
