import styled from 'styled-components';

const Page = styled.div`
  align-items: center;
  color: #F8F9F7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 960px;
  min-height: 100vh;
  padding: 1.45rem 1.0875rem;
  padding-top: 5rem;
`;

export default Page;
