import React from 'react';
import styled from 'styled-components';

import GitHubLogo from '../../images/icons/github.png';
import TwitterLogo from '../../images/icons/twitter.png';
import LinkedInLogo from '../../images/icons/linkedin.png';

const icons = [
  {
    href: 'https://github.com/bguzmanrio',
    imgPath: GitHubLogo,
    alt: 'GitHub Profile',
  },
  {
    href: 'https://twitter.com/xack1991',
    imgPath: TwitterLogo,
    alt: 'Twitter Profile',
  },
  {
    href: 'https://www.linkedin.com/in/borja-guzmán-del-río-15306781',
    imgPath: LinkedInLogo,
    alt: 'LinkedIn Profile',
  },
];

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

const Links = () => (
  <LinkWrapper>
    {icons.map(icon => (
      <a href={icon.href} style={{ maxWidth: '50px' }} key={icon.href}>
        <img style={{ marginBottom: 0 }} src={icon.imgPath} alt={icon.alt} />
      </a>
    ))}
  </LinkWrapper>
);

export default Links;
