import React from 'react';

import RoundImage from './RoundImage';
import Links from './Links';
import SEO from '../seo';

const MainHeader = () => (
  <div>
    <SEO title="Borja Guzman | JS Engineer ar Spotahome" keywords={['react', 'js', 'frontend', 'developer']} />
    <RoundImage />
    <Links />
  </div>
);

export default MainHeader;
