import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    transition: font-size, ease-in .1s;
  }
  * {
    font-family: 'Roboto', sans-serif;
  }

  body {
    background-color: #173E43;
  }

  @media (min-width: 768px) {
    html {
      font-size: 24px!important;
      
    }
  }
`;

export default GlobalStyle;
