import React from 'react';
import PropTypes from 'prop-types';

import Page from './common/Page';
import Footer from './common/Footer';
import GlobalStyles from './common/global';

import './common/normalize.css';

const Layout = ({ children }) => (
  <Page>
    <GlobalStyles />
    {children}
    <Footer />
  </Page>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};


export default Layout;
