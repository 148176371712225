import styled from 'styled-components';

const Card = styled.main`
  background-color: transparent;
  border: 2px solid #F8F9F7;
  border-radius: 4px;
  box-shadow: 0px 2px 5px 2px rgba(4, 3, 3, .3);
  padding: 1.45rem;
`;

export default Card;
